<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
    <navbar style="position:fixed;width:100%;z-index:20;background: rgb(255, 255, 255,0.5);"/>
    <!-- <navbar /> -->
  <br>
  <br>
  <br>
  <br>
  <router-view/>
  <br><br>
  <footer_main/>
</template>

<script>
import navbar from '@/components/includes/navbar.vue'
import footer_main from '@/components/includes/footer.vue'
// import HomeView from '@/views/HomeView.vue'
export default{
  components:{
        navbar,
        footer_main
            // HomeView
  }
}
</script>
<style lang="scss">
* {
  font-family: "Nunito Sans", sans-serif;
}

.sticky-header {
  // background-color: #e9f5ff;
  // color: #fff;
  padding: 1em;
  text-align: center;
}

@supports (position: sticky) {
  .is-sticky {
    position: sticky;
    top: 0; // Threshold is required
  }
}
:root{
  --backround-main-color: #F3F6FD; 
  --main-font-color:rgb(70, 70, 70) ;
  
}
#app {
  // font-family: "Ubuntu Sans", sans-serif;
  // font-optical-sizing: auto;
  // font-weight: 400;
  // font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--main-font-color) ;
  // height:100vh;
  // padding-left:5px;
  overflow: auto;
  // padding-right:5px;
  background: var(--backround-main-color);
}

nav {
  // padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
