<template>
    <section> 
        <a-divider color="grey"></a-divider>
        <div style="width:100%;margin:auto;background-color: white;padding:10px; border-radius: 10px;padding-bottom:70px;padding-top:70px;text-align:start">
            <a-row style="width:100%;max-width:1400px;margin:auto">
                <a-col :sm="24" :xs="24" :md="6" :lg="6">
        <img src="../../assets/logo.png" style="width:150px;" />
        <br>
        <br>
                    <!-- <h3><b>Luminary Workforce Solutions</b></h3> -->
                    
                    <b>Business Address</b>
                    <p>Chordiya Building, Gangadham -<br> Shatrunjay Rd, Kondhwa Budruk, <br>Pune, Maharashtra, India</p>
                    <p>Pin: 411048</p>
                    <!-- <p>INFRONT Water Tank, XYZRRR Township</p> -->
                </a-col>
                <a-col :sm="12" :xs="12" :md="4" :lg="4">
                    <h3><b>Industries</b></h3>
                    <p>Information Technology</p>
                    <p>Banking</p>
                    <p>Marketing</p>
                    <p>Medical</p>
                    
                </a-col>
                <a-col :sm="12" :xs="12" :md="4" :lg="4">
                    <h3><b>Services</b></h3>
                    <p>Recruitment Process</p>
                    <p>Consulting</p>
                    <p>Marketing</p>
                    <p>Medical</p>
                </a-col>
                <a-col :sm="12" :xs="12" :md="4" :lg="4">
                    <h3><b>Social Links</b></h3>
                    <p>Linkedin</p>
                    <p>Instagram</p>
                    <p>Facebook</p>
                    <p>Youtube</p>
                    
                </a-col>
                <a-col :sm="12" :xs="12" :md="4" :lg="4">
                    <h3><b>Pages</b></h3>
                    <p>Terms & conditions</p>
                    <p>Privacy Policy</p>
                    <p>Cookie policy</p>
                    <!-- <p>Youtube</p> -->
            </a-col>
        </a-row>
        <a-row style="width:100%;max-width:1400px;margin:auto;margin-top:70px;display: flex;gap:10px;justify-content: space-between;">
            <!-- <a-col :sm="24" :xs="24" :md="12" :lg="12"> -->
                <h1 style="font-size:10px;margin:0;padding:0">All ©️Copyrights 2024 Reserved <b>Luminary workforce solutions </b> </h1>
            <!-- </a-col> -->
                <!-- <a-col :sm="24" :xs="24" :md="12" :lg="12"> -->
                    <h1 style="font-size:10px;margin:0;padding:0">Created By: <a href="https://crekaa.com">CREKAA</a> </h1>
                <!-- </a-col> -->
        </a-row>
    </div>
    </section>
</template>
<script>
export default {
    name:"footer_main"
}
</script>
<style scoped>

</style>