<template>
  
    <nav :class="{ 'nav-open': isNavOpen }" style="position:fixed;width:100%;z-index:20;background: rgb(255, 255, 255,0.5);background: rgb(0,213,218);
background: linear-gradient(217deg, rgba(0,213,218,1) 6%, rgba(192,195,255,1) 53%, rgba(173,180,255,1) 85%);">
      <div style="color:black;padding-left:10px;padding-top:5px;padding-bottom:10px;">
    <a-row>
      <a-col :sm="0" :xs="0" :md="12" :lg="12">
      <div style="display: flex;gap:20px;">
<p style="margin:0;padding:0;display: flex;gap:10px;"><ion-icon style="font-size:15px" name="call-outline"></ion-icon><a  class="icon-socialmedia"  href="tel:+12053774399">(+1) 205 377 4399</a></p>
<p style="margin:0;padding:0;display: flex;gap:10px;"><ion-icon style="font-size:15px" name="mail-outline"></ion-icon><a  class="icon-socialmedia"  href="mailto:info@luminaryworkforcesolutions.com"> info@luminaryworkforcesolutions.com </a></p>
<p style="margin:0;padding:0;display: flex;gap:10px;"><ion-icon style="font-size:15px" name="location-outline"></ion-icon><a  class="icon-socialmedia" style=" max-width: 250px; display: inline-block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" href="https://www.google.com/maps/dir//Chordiya+Residency,+Gangadham+-+Shatrunjay+Rd,+Kondhwa+Budruk,+Pune,+Maharashtra+411048+-+Business+Address/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3bc2eb7a791b4fb5:0xf73100ef43f3da5d?sa=X&ved=1t:3061&ictx=111">
    Chordiya Building, Gangadham - Shatrunjay Rd, Kondhwa Budruk, Pune, Maharashtra 411048 - Business Address
</a>
</p>
      </div>
      </a-col>
      <a-col :sm="24" :xs="24" :md="12" :lg="12">
      <div style="display: flex;gap:20px;justify-content: space-evenly;">
        <ion-icon  class="icon-socialmedia" name="logo-facebook"></ion-icon>
        <ion-icon class="icon-socialmedia"  name="logo-youtube"></ion-icon>
        <ion-icon class="icon-socialmedia"  name="logo-twitter"></ion-icon>
        <ion-icon  class="icon-socialmedia" name="logo-instagram"></ion-icon>
        <ion-icon  class="icon-socialmedia" name="logo-linkedin"></ion-icon>
        <ion-icon  class="icon-socialmedia" name="logo-whatsapp"></ion-icon>
        <p class="icon-socialmedia" style="margin:0;padding:0"><a class="icon-socialmedia"  href="tel:+919179939106">(+91) 9179939106</a></p>
      </div>

      </a-col>
      
    </a-row>
  </div>
        <div style="padding-top:10px;padding-bottom:10px;background: white" >

            <a-row >
            <a-col :xs="21" :sm="21" :md="2" :lg="4" style="display: flex;" >
                <!-- <h3 >&nbsp;&nbsp;&nbsp;<b>Luminary Workforce Solutions</b></h3> -->
                <img src="../../assets/logo.png" style="width:150px;margin-left:20px" />
            </a-col>
            <a-col :xs="3" :sm="3" :md="0" :lg="0"  >
              <a-button
            type="text"
      style="border:none"
      @click="showDrawer('default')"
    >
    <ion-icon style="width:25px;height:25px" name="menu-outline"></ion-icon>
  
  </a-button>
            </a-col>
              <a-col :xs="0" :sm="0" :md="20" :lg="20">
            <a-row >
            <a-col :xs="0" :sm="0" :md="3" :lg="2" :offset="12" style="display: flex;flex-direction: column;justify-content: center;;height:40px" >
                <!-- Content for the first column -->
             <h4 class="nav-item" @click="scrollToAboutUs"> About</h4>
            </a-col>
            <a-col :xs="0" :sm="0" :md="3" :lg="2"  style="display: flex;flex-direction: column;justify-content: center;;height:40px">
                <!-- Content for the second column -->
                <h4 class="nav-item" @click="scrollToWorks">Works</h4>
            </a-col>
            <a-col :xs="0" :sm="0" :md="3" :lg="2"  style="display: flex;flex-direction: column;justify-content: center;;height:40px">
              <!-- Content for the third column -->
              <h4 class="nav-item" @click="scrollToSolutions">Solutions</h4>
            </a-col>
            <a-col :xs="0" :sm="0" :md="3" :lg="3"  style="display: flex;flex-direction: column;justify-content: center;;height:40px">
                <!-- Content for the fourth column -->
             <h4 class="nav-item" @click="scrollToContactUs">Contact us</h4>
            </a-col>
            <!-- <a-col>
              {{ this.homeSecondBaner_002 }}
            </a-col> -->
        </a-row>
    </a-col>
    
    
</a-row >

</div>
</nav>
<a-drawer
      width="80%"
      :size="size"
      :visible="open"
      @close="onClose"
    >
      <template #title>
        <img src="../../assets/logo.png" style="width:150px;margin-left:20px" />

      </template>
    
      <!-- <template #footer>
        <a-button style="margin-right: 8px" @click="onClose">Cancel</a-button>
        <a-button type="primary" @click="onClose">Submit</a-button>
      </template> -->
      <a-card title="About Us "   @click="scrollToAboutUs" :bordered="false" style="width: 100%;margin-bottom:10px">
        
    </a-card>
    <a-card title="Our Works" @click="scrollToWorks" :bordered="false" style="width: 100%;margin-bottom:10px">
      
    </a-card>
    <a-card title="Solutions" @click="scrollToSolutions" :bordered="false" style="width: 100%;margin-bottom:10px">
    </a-card>
    <a-card title="Contact Us" @click="scrollToContactUs"  :bordered="false" style="width: 100%;margin-bottom:10px">
      
    </a-card>
      
      
      
    </a-drawer>

  </template>
  
  
  <script>
//  import { ref } from "vue";

import homeSecondBaner_002 from "@/components/home/002_home_secondary_baner.vue"
import home_contact_us_007 from "@/components/home/007_home_contact_us.vue"
import ready_to_start_006  from "@/components/home/006_ready_to_start.vue"
import home02child_banner_003 from "@/components/home/003_home02child_banner.vue"
  export default {
    name: 'navbar',
    data() {
    return {
      isNavOpen: false,
      open:false,
      size:"default",
      homeSecondBaner_002:homeSecondBaner_002,
      home_contact_us_007 : home_contact_us_007,
      ready_to_start_006: ready_to_start_006,
      home02child_banner_003:home02child_banner_003
    };
  },
  mounted() {
      this.isNavOpen = true;
    
  },
  methods:{
    showDrawer  (val)  {
      this.size = val;
      this.open = true;
    },

     onClose  ()  {
      this.open = false;
    },

    scrollToSolutions(){
      this.open = false;
      this.ready_to_start_006.methods.scrollToRef()
    },
    scrollToWorks(){
      this.open = false;

      this.home02child_banner_003.methods.scrollToRef()
    },scrollToContactUs(){
      this.open = false;

      this.home_contact_us_007.methods.scrollToRef()
    },
    scrollToAboutUs(){
      this.open = false;

      this.homeSecondBaner_002.methods.scrollToRef()
    }
  }
  };
  </script>
 
 <style scoped>
 .icon-socialmedia:hover{
  /* background-color:red; */
  color:white;
  cursor:pointer
 }
 .icon-socialmedia{
  /* background-color:red; */
  color:black;
  cursor:pointer
 }
 .logo{
  margin-top:5px;
    text-align: start;
 }
 .nav-item{
   /* margin-top:10px; */
   /* padding-bottom:5px; */

 }
 
 .nav-item:hover{
  color: #1f1bff;
  /* border-bottom:1px solid black; */
cursor: pointer;
transition: all 0.5s;
 }
h1{
    font-size:27px
}nav {
  border-bottom: 1px solid rgba(91, 91, 91, 0.421);
  
  /* padding-bottom:10px; */
  transform: translateY(20px);
  transition-property: transform, border-bottom;
  transition-duration: 1500ms;
  transition-timing-function: ease-in-out;
}

.nav-open {
  transform: translateY(0px);
}
</style>