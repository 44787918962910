<template>
    <section style="padding-top:50px;padding-bottom:50px">
        <h1> <b>What Makes Us Different ?</b></h1>
        <a-row>
            <a-col :sm="24" :xs="24" :md="6" :lg="6" style="padding:10px">
                <div class="card-main">
                    <h3>Matching Algorithm powered by A</h3>
                    <p>Utilize sophisticated AI technology to match candidates with jobs based on the skills, experience and cultural fit, giving a closer fit between employers and employees.</p>

                </div>
            </a-col>
            <a-col :sm="24" :xs="24" :md="6" :lg="6" style="padding:10px">
                <div class="card-main">
                    <h3>Virtual Recruitment Events: </h3>
                    <p>Organize online career fairs and networking events for real-time interaction between job seekers and employers during hiring process.</p>
                </div>
            </a-col>
            <a-col :sm="24" :xs="24" :md="6" :lg="6" style="padding:10px">
                <div class="card-main">
                    <h3>Candidate Skill Development Programs </h3>
                    <p>Provide courses, workshops and resources for improving candidate’s expertise in their career domains as this will make them competitive in today’s highly demanding work environment.</p>
                </div>
            </a-col>
            <a-col :sm="24" :xs="24" :md="6" :lg="6" style="padding:10px">
                <div class="card-main">
                    <h3>Customized Employer Branding Packages </h3>
                    <p>Offer specific branding solutions that enable companies to increase their visibility.</p>

                </div>
            </a-col>
        </a-row>
    </section>
</template>
<script>
export default {
    name:"whatMakesUsDifferent011"
}
</script>
<style scoped>
.card-main{
    background: rgba(38, 38, 38, 0.0523);
    backdrop-filter: blur(20px);
    border-radius: 10px;

    padding:20px;
    height:100%;
    min-height: 130px;

    text-align: left;

}
.card-main h3{
 font-weight: bold;   
}
</style>
