<template>
    <section  >
        <div style=" width: 100%;">
            <h4 class="sub-headline">FAQ'S</h4>
            <!-- <h1 class="headline-h1">
                How does the recruitment <br>process <ion-icon name="chevron-forward-outline" style="font-size:40px;color:blue;"></ion-icon><ion-icon style="font-size:40px;color:blue;" name="chevron-forward-outline"></ion-icon> looks like?
            </h1> -->
            <br>
            <br>
            <div style="max-width:1200px;width:100%;margin:auto">
            <a-row >
                <a-col :sm="24" :xs="24" :md="12" :lg="12" class="customStyleClass">
                    <a-collapse :bordered="false" style="background: rgb(255, 255, 255);min-height:100px;;display: flex;flex-direction: column;justify-content: center;">
                        <template #expandIcon>
                            <img src="../../assets/icons/search.jpg" style="height: 50px; width: 50px; border-radius: 100%;" alt="">
                        </template>
                        <a-collapse-panel key="1" class="customStyleClass">
                            <template #header>
                                <a-row>
                                    <a-col :span="22" style="text-align: start;"><h3>How long does the recruitment process take?</h3></a-col>
                                    <a-col :span="2"><ion-icon name="add-outline"></ion-icon></a-col>
                                    
                                    
                                </a-row>
                            </template>
                            <p>The duration of the recruitment process varies depending on the role and the specific requirements of the client. Typically, it can take anywhere from a few days to several weeks.</p>
                        </a-collapse-panel>
                    </a-collapse>
                </a-col>

                <a-col :sm="24" :xs="24" :md="12" :lg="12" class="customStyleClass">
                    <a-collapse :bordered="false" style="background: rgb(255, 255, 255);min-height:100px;display: flex;flex-direction: column;justify-content: center;">
                        <template #expandIcon>
                            <img src="../../assets/icons/pie.jpg" style="height: 50px; width: 50px; border-radius: 100%;" alt="">
                        </template>
                        <a-collapse-panel key="2" class="customStyleClass">
                            <template #header>
                                <a-row>
                                    <a-col :span="22" style="text-align: start;"><h3>What industries do you specialize in?</h3></a-col>
                                    <a-col :span="2"><ion-icon name="add-outline"></ion-icon></a-col>
                                    
                                    
                                </a-row>
                            </template>
                            <p>We specialize in a wide range of industries including IT, healthcare, finance, engineering, and more. Our team has expertise in finding top talent across various sectors.</p>
                        </a-collapse-panel>
                    </a-collapse>
                </a-col>

                <a-col :sm="24" :xs="24" :md="12" :lg="12" class="customStyleClass">
                    <a-collapse :bordered="false" style="background: rgb(255, 255, 255);min-height:100px;display: flex;flex-direction: column;justify-content: center;">
                        <template #expandIcon>
                            <img src="../../assets/icons/grwoth.jpg" style="height: 50px; width: 50px; border-radius: 100%;" alt="">
                        </template>
                        <a-collapse-panel key="3" class="customStyleClass">
                            <template #header>
                                <a-row>
                                    <a-col :span="22" style="text-align: start;"><h3>What sets you apart from other recruitment agencies?</h3></a-col>
                                    <a-col :span="2"><ion-icon name="add-outline"></ion-icon></a-col>
                                    
                                    
                                </a-row>
                            </template>
                            <p>Our personalized approach, extensive industry knowledge, and commitment to client satisfaction set us apart. We build strong relationships with both clients and candidates to ensure the best fit for both parties.</p>
                        </a-collapse-panel>
                    </a-collapse>
                </a-col>

                <a-col :sm="24" :xs="24" :md="12" :lg="12" class="customStyleClass">
                    <a-collapse :bordered="false" style="background: rgb(255, 255, 255);min-height:100px;display: flex;flex-direction: column;justify-content: center;">
                        <template #expandIcon>
                            <img src="../../assets/icons/idea.jpg" style="height: 50px; width: 50px; border-radius: 100%;" alt="">
                        </template>
                        <a-collapse-panel key="4" class="customStyleClass">
                            <template #header>
                                <a-row>
                                    <a-col :span="22" style="text-align: start;"><h3>How do you screen candidates?</h3></a-col>
                                    <a-col :span="2"><ion-icon name="add-outline"></ion-icon></a-col>
                                </a-row>
                            </template>
                            <p>Our screening process includes resume reviews, in-depth interviews, skill assessments, and background checks. We ensure that only the most qualified candidates are presented to our clients.</p>
                        </a-collapse-panel>
                    </a-collapse>
                </a-col>

                <a-col :sm="24" :xs="24" :md="12" :lg="12" class="customStyleClass">
                    <a-collapse :bordered="false" style="background: rgb(255, 255, 255);min-height:100px;display: flex;flex-direction: column;justify-content: center;">
                        <template #expandIcon>
                            <img src="../../assets/icons/network.jpg" style="height: 50px; width: 50px; border-radius: 100%;" alt="">
                    
                            
                        </template>
                        <a-collapse-panel key="5" class="customStyleClass">
                            <template #header>
                                <a-row>
                                    <a-col :span="22" style="text-align: start;"><h3>What is your fee structure?</h3></a-col>
                                    <a-col :span="2"><ion-icon name="add-outline"></ion-icon></a-col>
                                </a-row>
                            </template>
                            <p>Our fees are competitive and based on the specific services provided. We offer flexible pricing models tailored to the needs of our clients. Contact us for a detailed quote.</p>
                        </a-collapse-panel>
                    </a-collapse>
                </a-col>

                <a-col :sm="24" :xs="24" :md="12" :lg="12" class="customStyleClass">
                    <a-collapse :bordered="false" style="background: rgb(255, 255, 255);min-height:100px;display: flex;flex-direction: column;justify-content: center;">
                        <template #expandIcon>
                            <img src="../../assets/icons/office.jpg" style="height: 50px; width: 50px; border-radius: 100%;" alt="">
                        </template>
                        <a-collapse-panel key="6" class="customStyleClass">
                            <template #header>
                                <a-row>
                                    <a-col :span="22" style="text-align: start;"><h3>How can I get started with your services?</h3></a-col>
                                    <a-col :span="2"><ion-icon name="add-outline"></ion-icon></a-col>
                                </a-row>
                            </template>
                            <p>Getting started is easy! Contact us via phone, email, or our website to discuss your recruitment needs. We'll schedule a consultation to understand your requirements and begin the search for the perfect candidates.</p>
                        </a-collapse-panel>
                    </a-collapse>
                </a-col>

            </a-row>
        </div>

        </div>
        <br><br>
        <br><br>
        <br><br>
    </section>
</template>
<script>
export default {
    data() {return{activeKey: 0,
}},name:"faqs_004",
}
</script>
<style scoped>
.customStyleClass{
    padding:5px;
}
.sub-headline{
    margin:0;
    padding:0;
    padding-top:50px;
    font-weight: bold;
    word-spacing: 10px; 
    color:grey
}
.headline-h1{
    text-align: center;
    font-weight: bold;
    padding-top:20px;
    font-size: 4vw;
  @media (max-width: 1200px) { font-size: 3rem; }
  @media (max-width: 992px) { font-size: 2.5rem; }
  @media (max-width: 768px) { font-size: 2rem; }
  @media (max-width: 576px) { font-size: 1.5rem; }
  
}
</style>