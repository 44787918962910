<template>
    <div  style="background-color: white;border-radius: 10px;padding:20px;min-height:480px;text-align:start;width:100%;max-width:1400px;margin:auto;border-radius:5px;">

    <section id="contact_us" style=" position: relative;min-height:400px; width: 100%"  class="main-section" >
        <img style="z-index: 1;position: absolute; left: 0;top: 0;width:100%;min-height:400px;max-height:700px;height:100%" src="../../assets/videos/beams-components.png" alt="">
        <div style=" z-index: 2;position: absolute;width: 100%;text-align:start">
            <section style="" >
        <div>
            <!-- <img src='../../assets/videos/bg-con.png' style="width:100%" alt="" srcset=""> -->
            <!-- <h1>Contact Us</h1> -->
            <div >
                <a-row style=" ">
                    <a-col :sm="24" :xs="24" :md="14" :lg="14" >
                        <div style="border-radius: 10px;">
                            <!-- <img style="width:100%;border-radius: 10px" src="https://img.freepik.com/free-photo/medium-shot-girl-posing-with-graduation-background_23-2150319879.jpg" alt=""> -->
                            <br><br>
                            <div style="height:100%;display:flex;align-items: center;justify-content: center;">

                            <div style="bottom:20px;padding:20px;background-color: rgba(0,0,0,0.1);border-radius:10px;max-width:500px">
                            
                                <h1 class="sub-headline">CONTACT US</h1>
                                    <h4 class="headline-h1">
    Any questions?<br> Write or call <img src="../../assets/videos/callus.png" style="width:40px;;height:40px" alt="" srcset=""> us <br> We will write back within 12 hrs.
</h4>
<a-row>
    <a-col :sm="24" :xs="24" :md="10" :lg="10" ><a  class="icon-socialmedia"  href="tel:+12053774399">(+1) 205 377 4399</a></a-col>
    <a-col :sm="24" :xs="24" :md="14" :lg="14" ><a  class="icon-socialmedia"  href="mailto:info@luminaryworkforcesolutions.com"> info@luminaryworkforcesolutions.com </a></a-col>
</a-row>
</div>
</div>

                        </div>
                    </a-col>
                    <a-col :sm="24" :xs="24" :md="10" :lg="10" >
                        <div class="card-contact-us">
                            <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab dolor impedit illo reprehenderit labore! Magni recusandae quaerat natus voluptate a veritatis quidem porro culpa, cumque, ad dolor assumenda nulla quo?Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab dolor impedit illo reprehenderit labore! Magni recusandae quaerat natus voluptate a veritatis quidem porro culpa, cumque, ad dolor assumenda nulla quo?</p> -->
                            <br>
                            <br>
                            <!-- <label for="email"> Email</label> -->
                            <a-input placeholder="E-mail address*"></a-input>
                            <br>
                            <br>
                            <!-- <label for="phone"> Phone</label> -->
                            <a-input placeholder="Phone Number (Optional)"></a-input>
                            <br>
                            <br>

                            <!-- <label for="name"> Your name</label> -->
                            <a-input placeholder="Your Name*"></a-input>
                            <br>
                            <br>

                            <!-- <label for="message"> Message</label> -->
                            <a-textarea rows="5" placeholder="Message*"></a-textarea>
                            <br>
                            <br>
                            <a-checkbox v-model:checked="concent_copy">Send copy of this message to my email address.</a-checkbox><br>
                            <a-checkbox v-model:checked="concent_acknoledgement">i concent to the processing of my personal data by the Adminstrator in accordance with the privacy policy.  </a-checkbox>
                            <br><br><a-button block style="float: right;" >Send </a-button>
                            <br>
                        </div>
                    </a-col>
                </a-row>
            </div>
        </div>
    </section>
        </div>
    </section>
</div>
   
    <br>
    <br>
</template>
<script>
export default {
    name:"home_contact_us_007",
    data(){
        return {
            concent_copy:true,
concent_acknoledgement:true,
        }
    },
    methods:{
        scrollToRef () {
            const element = document.getElementById('contact_us');
            if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
            // if (element) {
            //     const elementRect = element.getBoundingClientRect();
            //     const absoluteElementTop = elementRect.top + window.pageYOffset;
            //     const offset = window.innerHeight / 2 - elementRect.height / 2;
            //     const scrollToPosition = absoluteElementTop - offset;

            //     window.scrollTo({
            //     top: scrollToPosition,
            //     behavior: 'smooth'
            //     });
            // }
         
        },
    }
    
}
</script>

<style scoped>
.icon-socialmedia{
    color:grey
}
.icon-socialmedia:hover{
    color:blue
}
.main-section{
    height:100%;
  @media (max-width: 992px) { height:480px; }
  @media (max-width: 768px) { height:760px;}
  @media (max-width: 576px) { height:760px; }
}
.card-contact-us{
    padding:20px;
}
.sub-headline{
    margin:0;
    padding:0;
    padding-top:20px;
    max-width:250px;
    /* margin:auto; */
    font-weight: bold;
    word-spacing: 10px; 
    text-align:start;
    color:grey
}
.headline-h1{
font-size: 2.5vw;
text-align:start;
  @media (max-width: 1200px) { font-size: 3rem; }
  @media (max-width: 992px) { font-size: 2.5rem; }
  @media (max-width: 768px) { font-size: 2rem; }
  @media (max-width: 576px) { font-size: 1.5rem; }
}
</style>
