<template>
<a-row>
  <a-col :sm="24" :xs="24" :md="0" :lg="0">
    <a-float-button  href="https://wa.me/919179939106?text=Hi%20I%20wanted%20to%20recruit%20some%20employees"
  
      style="text-align: center;background-color: green;"
      size="large"
      type="primary"
      :badge="{ dot: true }" 
    >
      <template #icon>
        <ion-icon style="width:18px;height:18px" class="icon-socialmedia" name="logo-whatsapp"></ion-icon>
      </template>
    </a-float-button>
    
  </a-col>
</a-row>

  <div class="home">

    <homeMainBaner_001 style=""/>
    <homeSecondBaner_002 />
    <br>
    <div style="position: relative;margin:auto; ">
      <div style="position: absolute;width:100%; top: -300px; left: 50%; transform: translateX(-50%); z-index: 2;padding:10px">
        <homeAbout_us_005 />
      </div>
      <div style="margin-top: 300px; position: relative; z-index: 1;">
        <ready_to_start_006 />
      </div>
    </div>
    <br>
    <why_to_choose_009/>
    <br>
    <br>
    <br>
    <!-- <br> -->
    <our_mission_008/>
    <br>
    <our_services_details/>
    <!-- <br>
    <OurServices010/> -->
    
    <!-- <br> -->
    <home_contact_us_007/>
    <!-- <br> -->
    <faqs_004/>
  </div>
</template>

<script>
// @ is an alias to /src
import homeMainBaner_001 from '@/components/home/001_homeMainBaner.vue'
import homeSecondBaner_002 from '@/components/home/002_home_secondary_baner.vue'
import faqs_004 from '@/components/home/004_faqs.vue'
import homeAbout_us_005 from '@/components/home/005_homeAbout_us.vue'
import ready_to_start_006 from '@/components/home/006_ready_to_start.vue'
import home_contact_us_007 from '@/components/home/007_home_contact_us.vue'
import our_mission_008 from  '@/components/home/008_our_mission.vue'
import why_to_choose_009 from '@/components/home/009_why_to_choose.vue'
import our_services_details from '@/components/home/011_our_service_details.vue'
export default {
  name: 'HomeView',
  components: {
    homeMainBaner_001,
    homeSecondBaner_002,
    faqs_004,
    homeAbout_us_005,
    ready_to_start_006,
    home_contact_us_007,
    our_mission_008,
    why_to_choose_009,
    our_services_details,
  }
}
</script>
<style scoped>
.home{
  padding-left:15px;
padding-right:15px;
}
</style>