<template>
    <section class="why_to_choose_main">
        <br>
        <h1 class="headline-h1">Why you <br> should <span class="gradient-text">choose us</span>?</h1>
        <br><br>
        <a-row>
            <a-col :sm="24" :xs="24" :md="12" :lg="12" class="main-grout-sector-section">
                <!-- icon& title -->
                <a-row class="bg-card-main" >
                    <a-col :span="2" >
                        <ion-icon class="icon-01" name="accessibility-outline"></ion-icon>
                    </a-col>
                    <a-col :span="22" >
                        <h1 class="text-headline">  Personalized Approach</h1>
                        <p class="text-secondary">We take the time to understand the unique needs and goals of each client, ensuring tailored solutions that yield optimal results.</p>
                    </a-col>
                </a-row>
            </a-col>
            <a-col :sm="24" :xs="24" :md="12" :lg="12" class="main-grout-sector-section">
                <!-- icon& title -->
                <a-row class="bg-card-main">
                    <a-col :span="2">
                        <ion-icon class="icon-02" name="bonfire-outline"></ion-icon>
                    </a-col>
                    <a-col :span="22" >
                        <h1 class="text-headline">  Commitment to Excellence</h1>
                        <p class="text-secondary"> We are committed to excellence in everything we do, from the quality of our service to the outcomes we achieve for our clients.</p>
                    </a-col>
                </a-row>
            </a-col>
            <a-col :sm="24" :xs="24" :md="12" :lg="12" class="main-grout-sector-section">
            
<a-row class="bg-card-main">
                    <a-col :span="2">
                        <ion-icon class="icon-03" name="earth-outline"></ion-icon>
                    </a-col>
                    <a-col :span="22" >
                        <h1 class="text-headline">  Extensive Network</h1> 
                        <p class="text-secondary">With a vast network of top-tier employers and talented professionals, we have the resources to match the right candidates with the right opportunities.</p>
                    </a-col>
                </a-row>
            </a-col>
            <a-col :sm="24" :xs="24" :md="12" :lg="12" class="main-grout-sector-section">
            <a-row class="bg-card-main">
                    <a-col :span="2">
                        <ion-icon class="icon-04" name="golf-outline"></ion-icon>
                    </a-col>
                    <a-col :span="22" >
                        <h1 class="text-headline">  Expertise</h1> 
                        <p class="text-secondary">Our team of experienced recruiters brings a wealth of knowledge and insights to the table, providing invaluable guidance and support throughout the recruitment process.</p>
                    </a-col>
                </a-row>
            </a-col>
            <a-col :sm="24" :xs="24" :md="12" :lg="12" class="main-grout-sector-section">
            <a-row class="bg-card-main">
                    <a-col :span="2">
                        <ion-icon class="icon-05 " name="bulb-outline"></ion-icon>
                    </a-col>
                    <a-col :span="22" >
                        <h1 class="text-headline">  Innovation</h1> 
                        <p class="text-secondary"> We leverage cutting-edge technology and industry best practices to stay ahead of the curve and deliver innovative solutions that drive success.</p>
                    </a-col>
                </a-row>
            </a-col>
        </a-row>
    </section>
</template>
<script>
export default {
    name:"why_to_choose_009"
}
</script>

<style scoped>
.gradient-text{
background: -webkit-linear-gradient(217deg, rgba(3,0,214,1) 18%, rgba(0,213,218,1) 85%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.why_to_choose_main{
    max-width:1400px;
    margin:auto;
}
.headline-h1{
    margin:0;
    padding:0;
    padding-top:50px;
    font-weight: bold;
}
.main-grout-sector-section{
    
   
    padding:10px
}
.text-headline{
    /* padding-top:10px; */
    padding-left:22px;
    font-size:18px;
    font-weight: bold;
    line-clamp: initial;
    text-align: start;
}
.text-secondary{
    text-align:start;
    font-size:14px;
    padding-left:22px;
}
.bg-card-main{
    background: white;
    padding:10px;
    border-radius: 10px;;
}
.icon-01{
    
    color: red;
    padding:10px;border-radius:10px;
    font-size:22px;
    background: rgba(255, 0, 0, 0.218);
}
.icon-02{
    color: rgb(174, 0, 255);
    padding:10px;border-radius:10px;
    font-size:22px;
    background: rgba(174, 0, 255, 0.218);
}
.icon-03{
    color: rgb(255, 0, 115);
    padding:10px;border-radius:10px;
    font-size:22px;
    background: rgba(255, 0, 115, 0.218);
}
.icon-04{
    
    color: rgb(21, 0, 255);
    padding:10px;border-radius:10px;
    font-size:22px;
    background: rgba(21, 0, 255, 0.218);
}
.icon-05{
    
    color: rgb(255, 140, 0);
    padding:10px;border-radius:10px;
    font-size:22px;
    background: rgba(255, 140,0, 0.218);
}

</style>