<template>
    <section style="background-color:white;max-width:1400px;width:100%;margin:auto;border-radius:5px;z-index:2" class="main-section">
        <a-row>
            <a-col :sm="24" :md="12" :xs="24" :lg="12">
                <section  style=" position: relative;   height: 300px;  width: 100%">
        <img style="z-index: 1;position: absolute; left: 0;top: 0;width:100%;height:300px" src="../../assets/videos/beams-components.png" alt="">
        <div style=" z-index: 2;position: absolute;width: 100%;text-align:start">
            <br>
            <br>
            <br>
            <h4 class="sub-headline">TESTIMONIALS</h4>
            <h1 class="headline-h1">
                What our clients <img src="../../assets/videos/clientthink.png" style="width:40px;;height:40px" alt="" srcset=""> think about us?
            </h1>
        </div>
    </section>
    
    <!-- <h4 class="sub-headline">TESTIMONIALS</h4>
                <h1 class="headline-h1" style="margin-bottom:50px;">
                What Our Client Think About Us?
            </h1> -->
        </a-col>
            <a-col :sm="24" :md="12" :xs="24" :lg="12">
                <swiper
    :slidesPerView="1"
    :spaceBetween="30"
    :loop="true"
    :pagination="{
      clickable: true,
      dynamicBullets: true,
    }"
     :autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }"
    :navigation="false"
    :modules="modules"
    class="mySwiper"
  >
    <swiper-slide v-for="review in reviews" :key="review.key" >
        <div style="background-color: #f2f3f7;padding:10px;border-radius:10px;min-height:300px;">
        <a-row>
            <a-col>
  <a-avatar-group
    :max-count="2"
    max-popover-trigger="click"
    size="large"
    :max-style="{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }"
  >
    <a-avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
    <a-avatar style="background-color: #f56a00">K</a-avatar>
    <a-tooltip title="Ant User" placement="top">
      <a-avatar style="background-color: #87d068">
        <template #icon><UserOutlined /></template>
      </a-avatar>
    </a-tooltip>
    <a-avatar style="background-color: #1890ff">
      <template #icon><AntDesignOutlined /></template>
    </a-avatar>
  </a-avatar-group>
            </a-col>
            <a-col>
                <br>
                <br>
                <p style="font-weight: bold;text-align: start;line-height: 20px;padding-right:30px;padding-left:30px">"{{ review.content }}"</p>
                <br>
                <br>
                <br>
                <br>
            </a-col>

            <a-col :span="18" style="text-align: start;padding-left:10px;">
                
            <h4 style="margin:0">{{review.review_from}}</h4>
                <p style="margin:0">{{review.designation}}</p>
            </a-col>
            <a-col :span="6">
            <a>Read More +</a></a-col>

        </a-row>
    </div>
    <br>
    <br>

        </swiper-slide>
    
  </swiper>
            </a-col>
            

        </a-row>
    </section>
    <br>
    <br>
    <br>
    <br>
</template>
<script>
 import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Pagination, Navigation,Autoplay } from 'swiper/modules';


export default {
    
    name:'homeAbout_us_005',
    data() {
      return {
        modules: [Pagination, Navigation, Autoplay],
        reviews: [  {   
            "key": "1",
            "content": "The team at Luminary Workforce Solution exceeded my expectations. They were professional, responsive, and truly understood our needs. Thanks to them, we filled several critical positions quickly and efficiently.",
            "review_from": "John Doe 01",
            "designation": "CEO, Tech Innovators"
        },
        {   
            "key": "2",
            "content": "I had an excellent experience with Luminary Workforce Solution. They provided top-notch candidates who were a perfect fit for our company culture. The hiring process was smooth and hassle-free.",
            "review_from": "Jane Smith 02",
            "designation": "HR Manager, Creative Solutions"
        },
        {   
            "key": "3",
            "content": "Their expertise in the field is unmatched. They helped us find highly qualified professionals for our new project, which has significantly boosted our productivity and innovation.",
            "review_from": "Robert Brown 03",
            "designation": "Director of Operations, Future Enterprises"
        },
        {   
            "key": "4",
            "content": "I am thoroughly impressed with the quality of service provided by Luminary Workforce Solution. The recruiters were diligent and communicative throughout the process, ensuring we found the best candidates for our open positions.",
            "review_from": "Emily Clark 04",
            "designation": "Marketing Head, Bright Ideas"
        },
        {   
            "key": "5",
            "content": "Luminary Workforce Solution is simply the best. They took the time to understand our needs and delivered candidates who not only met but exceeded our expectations. Highly recommended!",
            "review_from": "Michael Johnson 05",
            "designation": "Sales Manager, Growth Inc."
        }]
      };
    },
    components: {
      Swiper,
      SwiperSlide,
    },
}
</script>

<style scoped>
.sub-headline{
    margin:0;
    padding:0;
    padding-top:50px;
    max-width:280px;
    margin:auto;
    font-weight: bold;
    word-spacing: 10px; 
    color:grey
}
.main-section{
    padding:100px;
    @media (max-width: 1200px) {   padding:100px; }
  @media (max-width: 992px) {   padding:70px;  }
  @media (max-width: 768px) {   padding:50px; }
  @media (max-width: 576px) {  padding:20px; }
}
.headline-h1{
font-size: 2.5vw;

max-width:280px;margin:auto;
  @media (max-width: 1200px) { font-size: 3rem; }
  @media (max-width: 992px) { font-size: 2.5rem; }
  @media (max-width: 768px) { font-size: 2rem; }
  @media (max-width: 576px) { font-size: 1.5rem; }
}
</style>
