<template>
    <a-modal v-model:open="open_model_industries" style="width:80%;" >
        <template #footer>
        </template>
        <template #title>
            <h4 style="font-weight: bold;">{{ currentIndustry.industry }}</h4>
        </template>
        <div style="height:100%;;" :style="'background-color:'+currentIndustry.color_code">
            <a-row>
                <a-col :sm="24" :xs="24" :md="12" :lg="12">
                    <img :src="currentIndustry.image" alt="" style="padding:5px;width: 100%;height:100%;border-radius:10px;object-fit: cover;" srcset="">
                </a-col>
                <a-col :sm="24" :xs="24" :md="12" :lg="12" style="padding:5px;">
                    <strong style="font-size: 16px;">Description: </strong>
                    <p style="font-size: 16px;">{{ currentIndustry.description }}</p>
                    <strong style="font-size: 16px;">Details: </strong>
                    <p style="font-size: 16px;">{{ currentIndustry.details }}</p>
                </a-col>
            </a-row>
        </div>
    </a-modal>
    <!-- <section :style=" 'background-image: url('+require('../../assets/images/bg.jpg')+');padding:20px;background-repeat:no-repeat'" style="background-size: 100%;"> -->
        <section :style="sectionStyle" style="max-width:1400px;margin:auto;padding-top:50px;padding-bottom: 50px;">
            <h1 style="text-align: center;" class="sub-headline">INDUSTRIES</h1>
        <a-row justify="center">
            <a-col :sm="12" :xs="12" :md="8" :lg="6" v-for="industry in data_industries" :key="industry.key" class="column-card">
                <div class="card-main-hoverable" @click="showModal(industry.key)">
                    <h4 style="font-size: 13px;"><b>{{industry.industry.toUpperCase()}}</b></h4>
                </div>
            </a-col>
        </a-row>
    </section>
</template>

<script>
import { backTopProps } from 'ant-design-vue/es/float-button/interface';

export default {
    name: "OurServices010",
    data() {
        return {
            key: 1,
            open_model_industries: false,
            currentIndustry: {},
            data_industries: [
                {
                    "key": 1,
                    "color_code":"#ffffff",
                    "image":require('../../assets/images/IT REcruitment.jpg'),
                    "industry": "IT Recruitment",
                    "description": "Are you looking for the best IT recruiters in USA with exceptional networking and recruiting skills? Well, this blog does the trick for you. We are the list of top IT recruiters in USA who are absolutely unmatched when it comes to offering the best hiring services.",
                    "details": "Luminary streamlines IT Recruiters In USA with the help of highly skilled and professional professionals. The vetted data pool helps with candidate sourcing, skill assessment, and tailored matching, ensuring the best fit for job-employees for tech roles. All these research activities are carried out under the supervision of recruitment of qualified persons in the field of information technology."
                },
                {
                    "key": 2,
                    "image":require('../../assets/images/helgthcare2.jpg'),
                    "color_code":"#ffffff",
                    "industry": "Healthcare Recruitment",
                    "description": "Find your way in the world of medical aids with our full-service and dedicated healthcare recruiter in the US. Whether you’re an experienced physician looking for a new career or a healthcare facility in need of exceptional talent, we bridge the gap by connecting the right physicians with the right opportunities.",
                    "details": "Our dedicated team of experts understands the unique requirements of these particular projects, ensuring that each appointment is the perfect fit for your business. Elevate your medical practice with our proven ability to align passion with purpose and take your practice to new heights."
                },
                {
                    "key": 3,
                    "color_code":"#ffffff",
                    "image":require('../../assets/images/finance recruitment.jpg'),
                    "industry": "Finance Recruitment",
                    "description": "The renowned Financial Recruiter In USA industry is a dynamic force that combines exceptional financial talents that can fill and drive growth with the requirements of businesses. It thrives on identifying and tapping into top performers who not only have the necessary financial skills but also a strategic vision to navigate challenging financial situations. This is where Luminary comes into play.",
                    "details": "This role carefully matches candidates' talents and skills with the unique needs of your business objectives, empowering them to make informed decisions within the finance industry, develop, and adapt to the prevailing economic climate."
                },
                {
                    "key": 4,
                    "color_code":"#ffffff",
                    "image":require('../../assets/images/sales and marketing.avif'),
                    "industry": "Sales & Marketing Recruitment",
                    "description": "The more active Sales & Marketing Recruitment function offers the selection of employees. From traditional sales roles to digital marketing specialists, these opportunities span product promotion, customer engagement, branding, and more.",
                    "details": "The demand for tech-savvy professionals is increasing as the industry faces more e-commerce and social media. Professional channels include business boards, networking meetings and specialized departments. Preferred candidates must possess communication skills, creativity, talent and adaptability, as the business is constantly evolving to keep pace with the market to meet changing consumer behavior and technological advances."
                },
                {
                    "key": 5,
                    "color_code":"#ffffff",
                    "image":require('../../assets/images/Biotechnology.jpg'),
                    "industry": "Biotechnology Recruitment",
                    "description": "Being recruited through a biotechnology recruitment process that requires a strong innovation-driven workforce. Luminary’s biotechnology Recruitment solutions connect you with exceptional talent, from researchers to lab technicians to legal experts of all kinds.",
                    "details": "As industry experts, the recruiters at Luminary understand the critical role that human capital plays in promoting biotech success. Let us empower your journey of scientific discovery with qualified individuals who share your passion for pushing the boundaries of science and medicine."
                },
                {
                    "key": 6,
                    "color_code":"#ffffff",
                    "image":require('../../assets/images/incurance.jpg'),
                    "industry": "Insurance Recruitment",
                    "description": "Grow your team with our expert insurance broker solutions. Our specialized approach ensures you get top-notch professionals, whether you need underwriters, agents, claim adjusters, or actuaries. We at Luminary have a deep understanding of the industry, find and evaluate candidates, and facilitate the hiring process.",
                    "details": "We are committed to providing you with the right talent to meet your specific insurance needs, helping your organization thrive in this complex and dynamic industry."
                },
                {
                    "key": 7,
                    "color_code":"#ffffff",
                    "image":require('../../assets/images/manufacturing.jpg'),
                    "industry": "Manufacturing Recruitment",
                    "description": "Start a fulfilling journey in the world of Manufacturing with Luminary! As an integral part of our team, you will shape the future by adding innovation to construction recruitment.",
                    "details": "We provide a dynamic environment where the leader’s skills are developed and your business potential grows. From technical excellence to product refinements, we are committed to pushing boundaries and setting new industry standards through new hires. Join Luminary and hire a team of people dedicated to creating quality content that impacts lives. Take a leap in the industry – your opportunity is here waiting for you!"
                }
            ]
        }
    },
    computed: {
        sectionStyle() {
            return {
                backgroundImage: `url(${require('../../assets/images/bg.jpg')})`,
                padding: '20px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100%'
            };
        }
    },
    methods: {
        showModal(key) {
            this.key = key;
            this.currentIndustry = this.data_industries.find(industry => industry.key === key);
            this.open_model_industries = true;
        },
        closeModal() {
            this.open_model_industries = false;
        }
    }
}
</script>

<style scoped>

@media (max-width: 1000px) {
    section {
        
        background-size: cover !important;
        /* transform: rotateX(00deg); */
    }
}

.sub-headline {
  margin: 0;
  padding: 0;
  padding-top: 50px;
  font-weight: bold;
  word-spacing: 10px;
  color: grey;
}
.column-card {
    padding: 15px;
}
.card-main-hoverable {
    border-radius: 10px;
    display: flex;
    height: 100px;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    justify-content: center;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.card-main-hoverable:hover {
    box-shadow: rgba(0, 138, 231, 0.2) 0px 4px 12px;
    cursor: pointer;
}
</style>
