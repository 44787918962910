<template>
  <section
    class="responsive-height"
    style="position: relative; width: 100%"
    v-motion-roll-visible-once-top
    :initial="{
      opacity: 0,
      y: 200,
    }"
    :enter="{
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        stiffness: '200',
        delay: 500,
      },
    }"
    id="works"
  >
    <img
      style="z-index: 1; position: absolute; left: 0; top: 0; width: 100%"
      src="../../assets/videos/beams-basic-transparent.png"
      alt=""
    />
    <div style="z-index: 2; position: absolute; width: 100%">
      <h4 class="sub-headline">OUR WORK AREAS</h4>
      <h1 class="headline-h1">
        How does the recruitment <br />process
        <img
          src="../../assets/videos/arrows.png"
          style="width: 50px; height: 50px"
          alt=""
          srcset=""
        />
        looks like for job seekers?
      </h1>
<div style="padding:5px;">

      <swiper
    :slidesPerView="1"
    :spaceBetween="30"
    :loop="true"
    :pagination="{
      clickable: true,
      dynamicBullets: true,
    }"
     :autoplay="{
      delay: 1000,
      disableOnInteraction: false,
    }"
    :breakpoints="{
                        '640': {
                          slidesPerView: 2,
                          spaceBetween: 5,
                        },
                        '768': {
                          slidesPerView: 2.5,
                          spaceBetween: 5,
                        },
                        '900': {
                          slidesPerView: 3.5,
                          spaceBetween: 5,
                        }
                      }"
    :navigation="false"
    :modules="modules"
    class="mySwiper"
  >
    <swiper-slide v-for="for_jobSeeker in for_jobSeekers" :key="for_jobSeeker.key" >
      <div class="card" style="display: flex; gap: 30px">
        <a-row>
          <a-col :sm="24" :xs="24" :md="8" :lg="8">
            <img :src="for_jobSeeker.uimg" style="width: 100px" class="image" alt="" />
          </a-col>
          <a-col :sm="24" :xs="24" :md="16" :lg="16" style="
                display: flex;
                /* flex-direction: column; */
                justify-content: center;
                align-items: center;
              ">
          <div
              
            >
              <div>
                <div class="title">{{for_jobSeeker.titl}}</div>
                <p>
                  {{for_jobSeeker.description}}
                </p>
              </div>
            </div>
          </a-col>
        </a-row>
            
          </div>

        </swiper-slide>
    
  </swiper>
</div>

      <a-row>
        
        <!-- <a-col :sm="24" :xs="24" :md="12" :lg="8">
                  <img src="../../assets/images/talent-aquisiition.jpg" class="image" alt="">
                </a-col> -->
        <!-- </a-row> -->
        <!-- <br>          <br>          <br> -->

        <!-- <a-row> -->
        <!-- <a-col :sm="24" :xs="24" :md="12" :lg="8">
                  <img src="../../assets/images/executive searchcopy.jpg" class="image" alt="">
                
                </a-col> -->
      
        <!-- </a-row> -->
        <!-- <br>          <br>          <br> -->
        <!-- <a-row> -->
        
        <!-- <a-col :sm="24" :xs="24" :md="12" :lg="8">
                  <img src="../../assets/images/Recruitment Consulting1.avif" class="image" alt="">
                  
                </a-col> -->
        <!-- </a-row> -->
        <!-- <br>          <br>          <br> -->
        <!-- <a-row> -->
        <!-- <a-col :sm="24" :xs="24" :md="12" :lg="8">
                  <img src="../../assets/images/brand building.jpg" class="image" alt="">
                
                </a-col> -->
       
        
        
      </a-row>
      <!-- <br>          <br>          <br> -->
    </div>
  </section>
</template>
<script>

import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Pagination, Navigation,Autoplay } from 'swiper/modules';

export default {
  name: "home02child_banner_003",
  data() {
    return {
      modules: [Pagination, Navigation, Autoplay],
      for_jobSeekers:[
        {
          key:1,
          uimg:  require('../../assets/images/7331692-removebg-preview.png'),
          titl:'Talent Acquisition',
          description:"Partner with us to streamline your recruitment process and find top talent that fits your company culture and requirements. Whether you're looking for full-time employees or temporary contractors, we have the expertise to meet your needs."
        },
        {
          key:2,
          uimg:  require('../../assets/images/candidates (1).png'),
          titl:'Recruitment Consulting',
          description:"Benefit from our expertise and insights to optimize your recruitment strategy and attract the best talent in the market."
        },
        {
          key:3,
          uimg:  require('../../assets/images/employer-branding.png'),
          titl:'Employer Branding',
          description:"Enhance your employer brand and attract top talent with our customized employer branding solutions."        },
        {
          key:4,
          uimg:  require('../../assets/images/7331706-removebg-preview.png'),
          titl:'Payroll Management',
          description:"Simplify your payroll processes with our comprehensive payroll management services. We handle everything from paycheck processing to tax compliance, allowing you to focus on your core business operations.."
        },
        {
          key:5,
          uimg:  require('../../assets/images/Contracual Opportunities.png'),
          titl:'Contractual Opportunities',
          description:"Gain access to a diverse pool of skilled professionals for short-term projects or specialized assignments. Whether you need temporary staff to fill seasonal gaps or contractors for specific projects, we can connect you with the right talent to meet your business needs."
        },
        {
          key:6,
          uimg:  require('../../assets/images/crop-employee-removebg-preview.png'),
          titl:'Executive Search',
          description:"Access our extensive network of executive-level candidates to fill key leadership positions within your organization."
        }
      ]
    }
  },
  components: {
      Swiper,
      SwiperSlide,
    },
  methods: {
    scrollToRef() {
      const element = document.getElementById("works");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>
<style scoped>
.card {
  background: #f3f2f7;
  border-radius: 10px;
  padding: 10px;
  min-height: 200px;
  height:100%;
  text-align: start;
}
.card .title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}
.card p {
  font-size: 14px;
  /* font-weight: bold; */
  text-align: start;
  margin-bottom: 10px;
}
.sub-headline {
  margin: 0;
  padding: 0;
  padding-top: 50px;
  font-weight: bold;
  word-spacing: 10px;
  color: grey;
}
.headline-h1 {
  text-align: center;
  font-weight: bold;
  padding-top: 20px;
  font-size: 4vw;
  @media (max-width: 1200px) {
    font-size: 3rem;
  }
  @media (max-width: 992px) {
    font-size: 2.5rem;
  }
  @media (max-width: 768px) {
    font-size: 2rem;
  }
  @media (max-width: 576px) {
    font-size: 1.5rem;
  }
}
.image {
  height: 100%;
  max-height: 250px;
  /* width: 100%; */
  border-radius: 10px;
  object-fit: contain;
  margin:auto;
  background: radial-gradient(circle, rgba(31,27,255,0.6) 2%,  rgba(255,255,255,0.01) 52%);
  @media (max-width: 576px) {
    background: radial-gradient(circle, rgba(31,27,255,0.6) 12%,  rgba(255,255,255,0.01) 62%);
    
  }
  /* background: white;; */
}
.responsive-height {
  height: 500px;
  @media (max-width: 1023px) {
    height: 1600px;
  }
  @media (max-width: 992px) {
    height: 600px;
  }
  @media (max-width: 768px) {
    height: 750px;
  }
  @media (max-width: 576px) {
    height: 500px;
    
  }
}
</style>
