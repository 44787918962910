<template>
    <section >
        <!-- blob -->
        <!-- <div style="top:50px;right:0;position: absolute;" class="main-container">
        </div> -->

        <div>
            <a-row>
            <a-col :sm="24" :xs="24" :md="14" :lg="14">
                <div style="padding-top:40px;padding-bottom:20px" v-motion-pop 
                :initial="{
    opacity: 0,
    y: 100,
  }"
  :enter="{
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: '100',
      delay: 100,
    },
  }"
>
                <div style="max-width:1000px;margin:auto;text-align: left">
                <h1  class="headline-h1">Intigrating best talent with the best opportunity! </h1>
                <p>We organise the recruitment process for startups to 500 fortune companies.</p>
                <a-button type="primary" size="large" @click="scrollToContactUs">
                    <div style="display: flex;">
                        Let's get started
                        <ion-icon style="font-size: 22px;margin-top:2px;" name="arrow-forward-outline"></ion-icon>
                    </div>
                    
                </a-button>
                </div>
                </div>
            </a-col>
            <a-col :sm="24" :xs="24" :md="10" :lg="10">
                <img src="../../assets/videos/home-main_processed.png"  draggable="false" style="width:100%" alt="">
            </a-col>
            </a-row>
        </div>
    </section>
</template>
<script>
import home_contact_us_007 from "@/components/home/007_home_contact_us.vue"

export default {
    data(){
        return {

            home_contact_us_007 : home_contact_us_007
        }
    },
          name:"homeMainBaner_001",
   methods:{
    scrollToContactUs(){

      this.home_contact_us_007.methods.scrollToRef()
    },
   }
    
}
</script>
<style scoped>
.main-container{
    width: 500px;
    height: 14rem;
    border-radius: 50%;
    /* position: relative; */
    background-image: #a6ddf0;
    filter: blur(72px);
}
.headline-h1{
    font-size: 4vw;
  @media (max-width: 1200px) { font-size: 3.5rem; }
  @media (max-width: 992px) { font-size: 3rem; }
  @media (max-width: 768px) { font-size: 2.5rem; }
  @media (max-width: 576px) { font-size: 2rem; }
  
}
</style>