<template>
    <!-- <section style=" background: url(https://dl.dropbox.com/s/mpmu0gjtxv2x3fs/Webp.net-resizeimage%20%281%29.jpg?raw=1) no-repeat center center fixed; background-size: cover;"> -->
        <section :style=" 'background: url('+require('../../assets/images/bg-con.png')+ ') no-repeat center center fixed; background-size: cover;padding:5px;padding-top:50px;padding-bottom:50px;'">
        <a-row>
            <a-col :sm="24" :xs="24" :md="12" :lg="12" class="p-5">
                <div class="section ">
            <h2 style="font-weight: bold">Recruitment Process Outsourcing</h2>
            <p>Outsourcing the loads of your tedious Recruitment administrative work will free up your valuable workforce and allow them to focus on your core competencies. We deliver cost-effective, high-quality recruitment process outsourcing solutions and can take care of your administrative and clerical work for your recruitment agency and help you to streamline your procedures.</p>
            <p>We at Luminary Workforce Solutions specialize in delivering the support services that are inherent in the recruitment process, from CV sourcing, CV Validation, CV formatting and processing, through to job posting, reference chasing, CRB checks and interview scheduling. Our dedicated service delivery models let you outsource as much, or as little, as you like from simple job posting to a fully managed recruitment projects with high-end data security and trust.</p>
        </div>
<br>
        <div class="section">
            <h2 style="font-weight: bold">Build your dream team and manage it effortlessly</h2>
            <p>Focus on growth as we manage your external workforce needs, from recruitment to compliance. Don’t let location limit your search for top talent. Near or far, our reach helps you find the perfect fit for optimal cost efficiency and agility.</p>
        </div>
        <br>
        <div class="section">
            <h2 style="font-weight: bold;">Recruiting for permanent placements that last</h2>
            <p>We help you recruit top talent for all your permanent direct-hire roles, securing the skills needed for your organization’s ongoing success. We utilize our recruiting experts and have direct access to a wide range of talent, including hard-to-reach professionals who are not actively looking for a new job.</p>
        </div>

            </a-col>
            <a-col :sm="24" :xs="24" :md="12" :lg="12"  style="padding-top:40px">

                <h2 style="font-weight: bold;text-align: center;padding-bottom:20px">How We Process Candidates ?</h2>

                <a-timeline mode="alternate">
    <a-timeline-item><b>Attracting talent at scale </b>
                         <p><b>Talent acquisition at scale:</b>  We run dedicated and targeted candidate attraction campaigns across digital, leveraging our own media and paid sources.</p>
    </a-timeline-item>
    <a-timeline-item color="orange"><b>Assessing the right fit </b>
                         <p>We customize the assessment process based on your unique organizational needs, safeguarding your decision to hire.</p>
    </a-timeline-item>
    <a-timeline-item color="purple"><b>Finding the best match </b>
                         <p>We deliver a list of top candidates to choose from and work to secure a mutually beneficial salary.</p>
    </a-timeline-item>

    <a-timeline-item color="green"><b>Delivering to your needs </b>
                         <p>We take care of the entire permanent recruitment process or even just single parts of it; from initial sessions and candidate/client meetings, to actual placement, training & post-placement care.</p>
    </a-timeline-item>

  </a-timeline>




            </a-col>
            <a-col :sm="24" :xs="24" :md="24" :lg="24"  style="padding-top:40px">
                <whatMakesUsDifferent011/>
            </a-col>
        </a-row>
    </section>
</template>
<script>
import whatMakesUsDifferent011 from '@/components/home/012_what_makes_us_different.vue'

export default {
    name:"our_services_details",
    components:{
    whatMakesUsDifferent011,

    }

}
</script>
<style scoped>
.p-5{
    padding:30px;
    text-align: start;
    text-align: justify;
}
.section {
    /* margin: 20px 0; */
    padding: 20px;
    /* color:white; */
    background: rgba(38, 38, 38, 0.0523);
    backdrop-filter: blur(20px);
    border-radius: 10px;

    
}

</style>