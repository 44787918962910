<template>
      <section  style=" position: relative;   height: 100%; max-width:1400px;margin:auto;  width: 100%" id="" >
        <!-- <div style=" z-index: 2;position: absolute;width: 100%;"> -->
            
            <a-row>
                <a-col :sm="0" :xs="0" :md="12" :lg="12" class="bg-image">
                    <img style="width:100%;height:100%;max-height:500px;object-fit: cover;border-radius:10px;" src="../../assets/images/glob.webp" alt="">
                </a-col>
                <a-col :sm="24" :xs="24" :md="12" :lg="12" style="height:100%;max-height:500px;padding-top:30px;display: flex;justify-content: center;align-items: center;">
                    <h1 class="headline-h6">
                        <h1 class="headline-h1">Our Mission</h1>
                        Our mission is to bridge the gap between talent and opportunity, creating meaningful connections that drive success for individuals and businesses alike. With a focus on personalized service and industry expertise, we strive to exceed expectations and deliver exceptional results every step of the way.
                        <!-- How does the recruitment <br>process <img src="../../assets/videos/arrows.png" style="width:50px;;height:50px" alt="" srcset="">  looks like? -->
                    </h1>
                
                </a-col>
            </a-row>
        <!-- </div> -->
    </section>
</template>
<script>
export default {
    name:"our_mission_008"
}
</script>
<style scoped>
.sub-headline{
    margin:0;
    padding:0;
    /* padding-top:50px; */
    padding-bottom:10px;
    font-weight: bold;
    word-spacing: 10px; 
    color:rgb(88, 88, 88)
}
.headline-h1{
    background: -webkit-linear-gradient(217deg, rgba(3,0,214,1) 70%, rgba(0,213,218,1) 85%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
/* font-size: 2.5vw;
  @media (max-width: 1200px) { font-size: 3rem; }
  @media (max-width: 992px) { font-size: 2.5rem; }
  @media (max-width: 768px) { font-size: 2rem; }
  @media (max-width: 576px) { font-size: 1.5rem; } */
}
.headline-h6{
    text-align: justify;
    /* font-weight: bold; */
    /* padding-top:20px; */
    font-size: 1.1vw;
    /* background: rgb(31,27,255,0.2); */
    max-width:500px;
    /* padding:40px; */
    border-radius:10px;
    /* backdrop-filter: blur(5px); */
    margin:auto;
    color:rgba(13, 0, 54, 0.485);
  @media (max-width: 1200px) { 
    /* font-size: 3rem; */
padding:30px; }
  @media (max-width: 992px) { font-size: 0.5rem;
padding:38px; }
  @media (max-width: 768px) { font-size: 0.7rem;
padding:35px; }
  @media (max-width: 576px) { font-size: 1rem;
padding:30px; }
  
}


</style>