<template>
    <section  style="background-color: white;border-radius:5px;margin:auto; width:100%;max-width:1400px">
        <div id="about_us">
            <a-row>
            <a-col :sm="24" :xs="24" :md="14" :lg="14">
                <div style="padding-top:40px;padding-bottom:20px;"  v-motion-slide-right :initial="{
    opacity: 0,
    y: 100,
  }"
  :enter="{
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: '100',
      delay: 100,
    },
  }">
                    <div style="display: flex;justify-content: center;align-items: center;" class="height-manager">
                <div style="max-width:400px;margin:auto;text-align: left;" class="apddingmanager">
                <h1  class="headline-h1" >Who Are we <br>and what we do ?</h1>
                <p>
                  <br/>
                  Embark on a journey to success with <b>Luminary Workforce Solutions</b>. We are your trusted partner in navigating the dynamic world of career opportunities. Whether you're a job seeker searching for your next big break or an employer seeking top talent, we have the tools and expertise to make your goals a reality. Join us on the path to a brighter future!
                  <br/>
                  <br/>
                  At <b>Luminary Workforce Solutions</b>, we believe that every individual deserves a fulfilling career that aligns with their aspirations. Founded on the principles of integrity, innovation, and excellence, we are dedicated to empowering both job seekers and employers to reach their full potential.
                </p>
                <!-- <a-button type="primary" size="large">
                    <div style="display: flex;">
                        Contact us and get recruitment process
                        <ion-icon style="font-size: 26px;" name="arrow-forward-outline"></ion-icon>
                    </div>
                    
                </a-button> -->
                </div>
            </div>
                </div>
            </a-col>
            <a-col :sm="24" :xs="24" :md="10" :lg="10">
                <img src="../../assets/videos/homeanalytics.png"  draggable="false" style="width:100%" alt="">
            </a-col>
            </a-row>
            
            <OurServices010/>
            <home02child_banner_003/>
        </div>
    </section>
</template>
<script>
import home02child_banner_003 from "@/components/home/003_home02child_banner.vue"
import OurServices010 from '@/components/home/010_our_services.vue'
export default {
    name:"homeSecondBaner_002",
    // data(){ return {scrollToRef: null}},
    components:{
        home02child_banner_003,
            OurServices010,
    },
    mounted() {
        // this.scrollToRef=this.scrollToRef()
  },
    methods:{
        scrollToRef () {
            const element = document.getElementById('about_us');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
         
        },
    }
}
</script>

<style scoped>
.headline-h1{
font-size: 2.5vw;
  @media (max-width: 1200px) { font-size: 3rem; }
  @media (max-width: 992px) { font-size: 2.5rem; }
  @media (max-width: 768px) { font-size: 2rem; }
  @media (max-width: 576px) { font-size: 1.5rem; }
}
.height-manager{
    height: 500px;
  @media (max-width: 992px) {height: 500px }
  @media (max-width: 768px) {height: 100%}
  @media (max-width: 576px) {height: 100% }
}
.apddingmanager{
   padding: 0px;
  @media (max-width: 992px) {padding: 10px }
  @media (max-width: 768px) {padding: 20px}
  @media (max-width: 576px) {padding: 20px }
}
</style>
