<template>
    <section style="background-color:#373cd7;min-height:700px;width:100%;margin:auto;border-radius:5px;z-index:1" class="main-section">
        <div class="jointed-container" style="" >
            <!-- <h1>Hello There</h1> -->
            
        </div>
        <a-row id="solutions">
            <a-col :sm="24"  :xs="24" :md="14" :lg="14">
                <h4 class="sub-headline">READY TO START</h4>
                <br>
                <h1 class="headline-h1">Want to Scale Up Your Buisness with New Employees?</h1>
                
                <br><br><p style=" margin:0;margin:auto;
    padding:0;color:white;max-width:450px;text-align: start;">Our Extensive knowledge of the Recruitment Sector and our throughness in finding the best candidate employee for your buisness  is at the heart of our buisness.
    <br>
    <br>
    <br>
<a-button type="primary" size="large" @click="scrollToContactUs">
    <div style="display: flex;">
                        Contact us & start recruitment 
                        <ion-icon style="font-size: 22px;margin-top:2px;" name="arrow-forward-outline"></ion-icon>
                    </div>
</a-button>
</p>
<br>
<br>
         </a-col>
         <a-col :sm="24"  :xs="24" :md="8" :lg="8" >
            <br>
            <br>
                <img src="../../assets/videos/Status Page Balloons@2x.png" style="height:auto;width: 100%;" alt="">
         </a-col>
         </a-row>
    </section>
   
</template>
<script>
import home_contact_us_007 from "@/components/home/007_home_contact_us.vue"
export default {
    name:'ready_to_start_006',
data(){
        return {

            home_contact_us_007 : home_contact_us_007
        }
    },

     methods:{
        scrollToRef () {
            const element = document.getElementById('solutions');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
                
        },
    scrollToContactUs(){

      this.home_contact_us_007.methods.scrollToRef()
    },
   }
 
}
</script>

<style scoped>
.sub-headline{
    margin:0;
    padding:0;
    padding-top:50px;
    max-width:450px;
    margin:auto;
    font-weight: bold;
text-align: start;
    word-spacing: 10px; 
    color:rgb(213, 213, 213)
}
.jointed-container{
    height:300px;
    color:white;
    border-radius:10px;
    backdrop-filter: blur(4px);
    display: flex;flex-direction: column;justify-content: end;
    background: rgba(67, 67, 67, 0.193);
    @media (max-width: 1200px) {   height:300px; }
  @media (max-width: 992px) {   height:500px;  }
  @media (max-width: 768px) {   height:600px; }
  @media (max-width: 576px) {  height:650px; }
}
.main-section{
    padding:100px;
    @media (max-width: 1200px) {   padding:100px; }
  @media (max-width: 992px) {   padding:70px;  }
  @media (max-width: 768px) {   padding:50px; }
  @media (max-width: 576px) {  padding:20px; }
}
.headline-h1{
font-size: 2.5vw;

max-width:450px;margin:auto;
text-align: start;
color:white;
  @media (max-width: 1200px) { font-size: 3rem; }
  @media (max-width: 992px) { font-size: 2.5rem; }
  @media (max-width: 768px) { font-size: 2rem; }
  @media (max-width: 576px) { font-size: 1.5rem; }
}
</style>
